import { Box, HStack } from '@wpmedia/ads-layout'
import { Trans } from 'react-i18next/icu.macro'
import { useQuery } from 'react-query'
import { GetLimitsTokenResponse } from '@wpmedia/arc-access-v2-api'
import { tokensApi } from '../../api2'

export interface TokensCount {
	orgCount: number
	userCount: number
	orgLimit?: string
	userLmit?: string
}

const getLimits = async (): Promise<GetLimitsTokenResponse> => {
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
	return await tokensApi.getLimits()
}

export const useGetLimitsQuery = () =>
	useQuery(['tokens', 'getLimits'], getLimits)

export let TokenLimits = () => {
	let { isLoading, data, isError } = useGetLimitsQuery()

	if (isLoading) {
		return <></>
	}
	if (isError && data === undefined) {
		return <></>
	}
	let { orgCount, userCount, orgLimit, userLimit } =
		data as GetLimitsTokenResponse
	return (
		<HStack>
			<Box>
				<Trans i18nKey="tokens_table_user_count_label">
					Active user tokens: {{ userCount }}
				</Trans>
				{userLimit !== undefined && <span> / {userLimit}</span>}
			</Box>
			<Box>
				<Trans i18nKey="tokens_table_org_count_label">
					Active org tokens: {{ orgCount }}
				</Trans>
				{orgLimit !== undefined && <span> / {orgLimit}</span>}
			</Box>
		</HStack>
	)
}
