import { PermissionScope, AccessLevel } from '@wpmedia/arc-access-v2-api'
export const TokenType = {
	RESTRICTED: 'restricted',
	READ_ONLY: 'readOnly',
	ALL_ACCESS: 'general',
} as const

let TokenTypeArray = [...Object.values(TokenType)] as const
export type TokenTypes = typeof TokenTypeArray[number]

export const TokenTypeLabels = {
	[TokenType.RESTRICTED]: 'Restricted',
	[TokenType.READ_ONLY]: 'Read Only',
	[TokenType.ALL_ACCESS]: 'All Access',
}

export interface Token {
	authority: string
	id: string
	issued: string
	name: string
	org: string
	permissionScope: string
	provisioned: boolean
	tokenType: TokenTypes
	username: string
	last_used?: string
}

export interface SubmitNewToken {
	name: string
	permissionScope?: string
	tokenType: TokenTypes
	restricted: PermissionScope
}

export interface ApplicationPermission {
	[x: string]: AccessLevel
}

export enum Permission {
	NONE = '0',
	READ_ONLY = '1',
	FULL = '2',
}

export interface ConfirmTokenPageState {
	token?: string
	tokenType: TokenTypes
	restricted?: AccessLevel
}
