import { styled } from '@wpmedia/ads-system'

export let Backdrop = styled('div', {
	visibility: 'hidden',
	opacity: 0,
	background: 'rgba(0, 0, 0, 0.5)',
	width: '100%',
	height: '100%',
	top: 0,
	left: 0,
	position: 'fixed',
	pointerEvents: 'none',
	zIndex: 0,
})

export let DrawerUI = styled('div', {
	background: '#fff',
	width: '40%',
	height: '100%',
	overflow: 'auto',
	position: 'fixed',
	boxShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
	zIndex: 1000,
	variants: {
		position: {
			left: {
				top: 0,
				left: 0,
				transform: 'translateX(-100%)',
			},
			right: {
				top: 0,
				right: 0,
				transform: 'translateX(100%)',
			},
		},
	},
})

export let DrawerContainer = styled('div', {
	variants: {
		open: {
			true: {
				[`& ${Backdrop}`]: {
					visibility: 'visible',
					opacity: 1,
					pointerEvents: 'auto',
					zIndex: 999,
				},
				[`& ${DrawerUI}`]: {
					transform: 'translateX(0)',
				},
			},
		},
	},
})
