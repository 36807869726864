import { TableState } from '@tanstack/react-table'
import { Text } from '@wpmedia/ads-typography'
import { Trans } from 'react-i18next'

interface PaginationInfoProps {
	total: number // total # of items
	state: TableState // state from react-table
	// number of paginated result pages
	pages: number
}

export function PaginationInfo({ state, total, pages }: PaginationInfoProps) {
	if (pages <= 1) {
		return (
			<Trans i18nKey="token_table_items_label_all">
				<Text>Showing all {{ totalItems: total }} tokens</Text>
			</Trans>
		)
	}

	let endPage = state.pagination.pageSize * (state.pagination.pageIndex + 1)
	let from = state.pagination.pageSize * state.pagination.pageIndex + 1
	let to = total < endPage ? total : endPage

	return (
		<Trans i18nKey="token_table_items_label" transWrapTextNodes={Text}>
			<Text>
				Showing {{ from }} - {{ to }} of {{ totalItems: total }} access tokens
			</Text>
		</Trans>
	)
}
