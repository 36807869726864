import { useCheckPermissions, useUser } from '@shared/application-data'
import { PERMISSIONS, Token } from '../../types'

type ParseResult<T> =
	| { parsed: T; hasError: false; error?: undefined }
	| { parsed?: undefined; hasError: true; error?: unknown }

const safeJsonParse =
	<T,>(typeguard: (o: any) => o is T) =>
	(text: string): ParseResult<T> => {
		try {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
			let parsed = JSON.parse(text)
			return typeguard(parsed)
				? { parsed, hasError: false }
				: { hasError: true }
		} catch (error) {
			return { hasError: true, error }
		}
	}

function isPermissionScopeMethods(o: any): o is PermissionScopeMethods {
	return (
		'GET' in o ||
		'POST' in o ||
		'PUT' in o ||
		'DELETE' in o ||
		'HEAD' in o ||
		'OPTIONS' in o ||
		'ALL' in o
	)
}

interface PermissionScopeMethods {
	GET: string[]
	POST?: string[]
	PUT?: string[]
	DELETE?: string[]
	HEAD?: string[]
	OPTIONS?: string[]
	ALL?: string[]
}

export const useRevokedEnabled = (token: Token) => {
	let { hasPermissions } = useCheckPermissions()
	let { username, authority } = useUser()

	let {
		username: usernameToken,
		authority: authorityToken,
		permissionScope: permissionScopeStr,
	} = token
	if (usernameToken === username) return true

	if (
		!hasPermissions(PERMISSIONS.REVOKE) &&
		(username !== usernameToken || authorityToken !== authority)
	) {
		return false
	}

	let result = safeJsonParse(isPermissionScopeMethods)(permissionScopeStr)
	if (result.hasError) {
		return false
	}
	let permissionScopeMethods = result.parsed

	let permissionScope = ''
	if (
		permissionScopeMethods.ALL !== undefined &&
		permissionScopeMethods.ALL[0] === '*'
	) {
		permissionScope = 'admin'
	} else if (
		permissionScopeMethods.GET !== undefined &&
		permissionScopeMethods.GET[0] === '*'
	) {
		permissionScope = 'readOnly'
	} else if (
		permissionScopeMethods.POST !== undefined &&
		permissionScopeMethods.POST.length !== 1
	) {
		permissionScope = 'admin'
	} else {
		permissionScope = 'readOnly'
	}

	if (permissionScope === 'readOnly') {
		if (
			hasPermissions([PERMISSIONS.CREATE_READONLY, PERMISSIONS.CREATE_ADMIN])
		) {
			return true
		}
	} else if (permissionScope === 'admin') {
		if (hasPermissions([PERMISSIONS.CREATE_ADMIN])) {
			return true
		}
	} else if (permissionScope === 'restricted') {
		if (hasPermissions([PERMISSIONS.CREATE_ADMIN])) {
			return true
		}
	}

	return false
}
