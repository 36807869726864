import { useButton } from '@react-aria/button'
import { PressEvent } from '@react-types/shared'
import {
	InputRadio,
	OptionWrapper,
	RadioButton,
	TextDescription,
	Title,
} from './styles'
import { Key, useRef } from 'react'
import { VStack } from '@wpmedia/ads-layout'

interface OptionProps {
	id: Key
	value: string
	name: string
	description: string
	isSelected: boolean
	disabled?: boolean
	onPress: (e: PressEvent) => void
}

export const Option = ({
	id,
	value,
	name,
	description,
	isSelected,
	disabled,
	onPress,
	...props
}: OptionProps) => {
	let ref = useRef<HTMLButtonElement>(null)
	let { buttonProps } = useButton(
		{ onPress, isDisabled: disabled, ...props },
		ref,
	)

	return (
		<OptionWrapper
			className="option_custom"
			disabled={disabled}
			{...buttonProps}
			ref={ref}
		>
			<InputRadio
				name="token_type"
				value={value}
				type="radio"
				id={`option_${id}`}
				readOnly
				checked={isSelected}
			/>
			<RadioButton disabled={disabled}>
				<VStack>
					<label htmlFor={`option_${id}`}>
						<Title>{name}</Title>
					</label>
					<TextDescription>{description}</TextDescription>
				</VStack>
			</RadioButton>
		</OptionWrapper>
	)
}
