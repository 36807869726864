import { useQueries } from 'react-query'
import { createContext, PropsWithChildren } from 'react'
import type * as Types from './types'
import type { AccessPermissions, UserDataResponse } from '../../types'
import { applicationsApi, getUserInfo } from '../../api2'

export const UserContext = createContext<Types.ApplicationData | undefined>(
	undefined,
)

export let UserPermissionsProvider = ({ children }: PropsWithChildren<{}>) => {
	let [userQuery, applicationQuery] = useQueries([
		{
			queryKey: ['user'],
			queryFn: getUserInfo,
			staleTime: Infinity,
			select: (data: UserDataResponse): Types.UserData => {
				let permissions: AccessPermissions[] =
					data.permissions['API Access'].global
				return { ...data, permissions }
			},
			suspense: true,
		},
		{
			queryKey: ['applications'],
			queryFn: () => applicationsApi.getApplications(),
			staleTime: Infinity,
			suspense: true,
		},
	])
	if (userQuery.data === undefined || applicationQuery.data === undefined) {
		return null
	}

	/**
	 * We are checking for undefined above and rendering null if that is the case. So we are safe to
	 * assume that the query data is always defined here (any errors should be caught by error boundaries)
	 */
	return (
		<UserContext.Provider
			// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
			value={{ user: userQuery.data, applications: applicationQuery.data }}
		>
			{children}
		</UserContext.Provider>
	)
}
