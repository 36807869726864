import { TokenResponse } from '@wpmedia/arc-access-v2-api'
import { tokensApi } from '../../api2'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

/**
 * Given a token ID, a function is returned that will navigate the user to clone that token.
 * @param {string} id The id of the token to clone
 * @returns Function that navigates to the clone (new) token page
 */
export function useCloneToken(id: string) {
	let navigate = useNavigate()
	let tokenQuery = useQuery(['token', id], () => tokensApi.getToken({ id }), {
		enabled: false,
	})

	return () => {
		tokenQuery
			.refetch()
			.then((result) => {
				let token = result.data as TokenResponse

				navigate(`new/${token.tokenType}`, {
					state: {
						name: token.name,
						permissionScope: token.permissionScope,
					},
				})
			})
			.catch((err) => console.error(err))
	}
}
