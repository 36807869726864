import { ConfirmToolbar } from '@components/core/ConfirmToolbar'
import { Spacer } from '@components/core/Spacer'
import { useNotification } from '@wpmedia/ads-notification'

import { TokenDescriptionTextarea } from '@components/core/TokenDescription'
import { useApplications } from '@shared/application-data'
import { Box, Flex, Page, VStack } from '@wpmedia/ads-layout'
import { Heading } from '@wpmedia/ads-typography'
import {
	AccessLevel,
	ArcApplicationData,
	ArcApplicationGroup,
	PermissionScope,
	PostTokenOperationRequest,
	ResponseError,
} from '@wpmedia/arc-access-v2-api'
import { useCallback, useMemo } from 'react'
import type { SubmitHandler } from 'react-hook-form'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { select, Trans } from 'react-i18next/icu.macro'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import {
	createToken,
	CreateTokenRequest,
	CreateTokenResponse,
	isMaxNumberTokenReached,
	MaxNumberTokenReachedError,
	tokensApi,
} from '../../api2'
import { RouteURL, tokenTypeParamMap } from '../../constants/routes'
import { SubmitNewToken, TokenType, TokenTypes } from '../../types'
import { RestrictedTokenTable } from '../RestrictedTokenTable'

let useCreateToken = ({ onError }: { onError: (error: Error) => void }) => {
	let queryClient = useQueryClient()
	return useMutation(
		(data: CreateTokenRequest): Promise<CreateTokenResponse> => {
			if (data.tokenType === TokenType.RESTRICTED) {
				let body: PostTokenOperationRequest = {
					postTokenRequest: {
						name: data.name,
						tokenType: data.tokenType,
						permissionScope: data.permissionScope as PermissionScope,
					},
				}
				return tokensApi.postToken(body).catch(async (error: ResponseError) => {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
					let responseJson = await error.response.json()
					if (isMaxNumberTokenReached(responseJson)) {
						throw new MaxNumberTokenReachedError(
							responseJson.message,
							responseJson.limit,
							responseJson.errCode,
						)
					}
					// eslint-disable-next-line @typescript-eslint/no-unsafe-argument
					throw new Error(responseJson.message)
				})
			}
			let oldTokenBody = {
				name: data.name,
				permissionScope: data.permissionScope as string,
				tokenType: data.tokenType,
			}
			return createToken(oldTokenBody)
		},
		{
			// TODO the new token dont
			onMutate: async (token: CreateTokenRequest) => {
				await queryClient.cancelQueries('tokens')
				let previousTokens =
					queryClient.getQueryData<CreateTokenRequest[]>('tokens') ?? []
				queryClient.setQueryData<CreateTokenRequest[]>('tokens', (old = []) => [
					...old,
					token,
				])
				return { previousTokens, token }
			},
			onError: (error: ResponseError, _tokens, context) => {
				onError(error)
				queryClient.setQueryData('tokens', context?.previousTokens)
			},
			onSettled: () => queryClient.invalidateQueries('tokens'),
		},
	)
}

export function NewTokenLayout() {
	let { t } = useTranslation()
	let navigate = useNavigate()
	let { snack } = useNotification()
	let { tokenTypeParam = '' } = useParams<{
		tokenTypeParam: TokenTypes
	}>()
	let { state } = useLocation()
	let { name: cloneName, permissionScope: clonePermissionScope } = state as {
		name?: string
		permissionScope?: PermissionScope
	}

	let { groups } = useApplications()
	let restrictedDefaulValue = useMemo(
		() =>
			groups
				.reduce<ArcApplicationData[]>(
					(prev: ArcApplicationData[], { list }: ArcApplicationGroup) => [
						...prev,
						...list,
					],
					[],
				)
				.reduce<PermissionScope>(
					(acc: PermissionScope, { id }: ArcApplicationData) => ({
						[id]: AccessLevel.NONE,
						...acc,
					}),
					{},
				),
		[groups],
	)

	let tokenType = tokenTypeParamMap[tokenTypeParam]

	let onSuccess = useCallback(
		(data: CreateTokenResponse) => {
			snack.success(
				<Trans i18nKey="created_token_success_snackbar">
					{select`${tokenTypeParam}, general { All access token generated successfully. } readonly { Readonly token generated successfully. } other { Restricted token generated successfully. }`}
				</Trans>,
			)
			navigate('/dev-center/new/created', {
				state: data,
			})
		},
		[navigate, snack, tokenTypeParam],
	)

	let onError = useCallback(
		(error: Error) => {
			// Reused key: revoke_token_snackbar_error as unexpected error
			let errorMsg = <Trans i18nKey="revoke_token_snackbar_error"></Trans>
			if (error instanceof MaxNumberTokenReachedError) {
				let { limit, errCode } = error
				if (errCode === 'ErrUserLimitExceeded') {
					errorMsg = (
						<Trans
							i18nKey="create_token_error_user_limit_exceeded"
							values={{ limit }}
						></Trans>
					)
				}
				if (errCode === 'ErrGlobalLimitExceeded') {
					errorMsg = (
						<Trans
							i18nKey="create_token_error_global_limit_exceeded"
							values={{ limit }}
						></Trans>
					)
				}
			}
			snack.warn(errorMsg)
		},
		[snack],
	)

	let createTokenMutation = useCreateToken({ onError })

	if (tokenType === undefined) {
		navigate(RouteURL.HOME)
	}

	let form = useForm<SubmitNewToken>({
		mode: 'onChange',
		defaultValues: {
			tokenType,
			restricted: clonePermissionScope ?? restrictedDefaulValue,
			name: cloneName ? `Clone ${cloneName}` : '',
		},
	})

	let onSubmit: SubmitHandler<SubmitNewToken> = ({ restricted, name }) => {
		let tokenData: CreateTokenRequest = {
			name,
			permissionScope:
				tokenType === TokenType.RESTRICTED ? restricted : tokenType,
			tokenType,
		}

		createTokenMutation.mutate(tokenData, {
			onSuccess: (data: CreateTokenResponse) =>
				onSuccess({ ...data, restricted }),
		})
	}

	return (
		<FormProvider {...form}>
			<Page bg={'$gray100'} p="$0">
				<form onSubmit={form.handleSubmit(onSubmit)}>
					<ConfirmToolbar />
					<VStack css={{ paddingTop: '$3' }}>
						<VStack css={{ marginX: '$4' }}>
							<Heading
								level={2}
								// @ts-expect-error ADS type issue
								css={{
									fontSize: '26px',
									lineHeight: '30px',
									fontWeight: '500',
								}}
							>
								<Trans i18nKey="label_new_token_title">
									{select`${tokenTypeParam}, restricted { Create restricted access token } general { Create all access token } readonly { Create read-only token} other { }`}
								</Trans>
							</Heading>
							<Box css={{ height: '$3' }} />

							<Box css={{ width: '50%' }}>
								<Heading
									level={3}
									// @ts-expect-error ADS type issue
									css={{
										fontSize: '16px',
										lineHeight: '18px',
										fontWeight: '500',
										color: '#656A86',
									}}
								>
									<Trans i18nKey="label_new_token_desc">
										{select`${tokenTypeParam}, restricted { Restricted access tokens are configured on a granular control } general { All Access tokens have complete access to an organization's data in Arc. These tokens can be used to make HTTP requests to any Arc API endpoint. } readonly { Read-Only access tokens have complete read access to an organization's data in Arc. These tokens can be used to make HTTP requests to any Arc API endpoint using the GET, HEAD, and OPTIONS methods (Non-modifying POST requests can only be made to specific, pre-approved endpoints). Requests using any other HTTP verbs will fail. } other { }`}
									</Trans>
								</Heading>
							</Box>
							<Spacer />
							<Flex
								css={{
									alignItems: 'center',
									justifyContent: 'space-between',
									flexDirection: 'row',
								}}
							>
								<VStack css={{ width: '100%' }}>
									<Box css={{ width: '50%' }}>
										<TokenDescriptionTextarea />
									</Box>
									{tokenType === TokenType.RESTRICTED && (
										<>
											<Box css={{ height: '$3' }} />
											<Box>
												<Heading
													level={2}
													// @ts-expect-error ADS type issue
													css={{
														fontSize: '20px',
														lineHeight: '30px',
														fontWeight: '500',
													}}
												>
													{t(
														'label_new_restricted_token_table',
														'Configure endpoint access',
													)}
												</Heading>
												<Heading
													level={2}
													// @ts-expect-error ADS type issue
													css={{
														fontSize: '14px',
														lineHeight: '30px',
														fontWeight: '500',
													}}
												>
													{t(
														'label_new_restricted_token_table_desc',
														'Select below the individual access this token should have',
													)}
												</Heading>
											</Box>

											<Box>
												<RestrictedTokenTable />
											</Box>
										</>
									)}
								</VStack>
							</Flex>
						</VStack>
					</VStack>
				</form>
			</Page>
		</FormProvider>
	)
}
