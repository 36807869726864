import { Button } from '@wpmedia/ads-button'
import {
	Dialog,
	DialogBody,
	DialogFooter,
	DialogTitle,
} from '@wpmedia/ads-dialog'
import { VStack } from '@wpmedia/ads-layout'
import { Text } from '@wpmedia/ads-typography'
import { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Option } from './Option'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { tokenTypeRouteMap } from '../../constants/routes'
import { TokenType, PERMISSIONS } from '../../types'
import { PermissionsGate } from '@shared/application-data'

function NewTokenModal() {
	let modal = useModal()
	let { t } = useTranslation()

	let options = useMemo(
		() => [
			{
				value: TokenType.RESTRICTED,
				name: t('new_token_type_resctricted_name', 'Restricted access'),
				description: t(
					'new_token_type_resctricted_description',
					'Pick and choose which API endpoints a user has access to.',
				),
				privileges: [PERMISSIONS.CREATE_ADMIN, PERMISSIONS.CREATE_READONLY],
			},
			{
				value: TokenType.READ_ONLY,
				name: t('new_token_type_readonly_name', 'Read only (admin)'),
				description: t(
					'new_token_type_readonly_description',
					'Grant read only privileges for admin-level users.',
				),
				privileges: [PERMISSIONS.CREATE_READONLY],
			},
			{
				value: TokenType.ALL_ACCESS,
				name: t('new_token_type_all_access_name', 'All access (admin)'),
				description: t(
					'new_token_type_all_access_description',
					'Grant full privileges for admin-level users.',
				),
				privileges: [PERMISSIONS.CREATE_ADMIN],
			},
		],
		[t],
	)

	let [selectedOption, selectOption] = useState(TokenType.RESTRICTED)

	return (
		<Dialog
			aria-label={t('new_token_button_label', 'New access token')}
			size="large"
			isOpen={modal.visible}
			onDismiss={modal.remove}
		>
			<DialogTitle
				level="1"
				css={{ fontWeight: 500, fontSize: 32, lineHeight: 1.5 }}
			>
				{t('new_token_button_label', 'New access token')}
			</DialogTitle>
			<DialogBody>
				<Text as="label">
					{t('new_token_modal_main_text', 'Select a type of token to create:')}
				</Text>
				<VStack gap="$0" css={{ pt: '$xsmall', display: 'flex' }}>
					{options.map((option, key) => (
						<PermissionsGate
							key={key}
							privileges={option.privileges}
							errorProps={{ disabled: true }}
						>
							{(permissionGranted) => (
								<Option
									{...option}
									id={key}
									data-testid={`option-${option.value}`}
									data-pendo-id={`createtoken_option-${option.value}`}
									disabled={!permissionGranted}
									onPress={() => selectOption(option.value)}
									isSelected={option.value === selectedOption}
								/>
							)}
						</PermissionsGate>
					))}
				</VStack>
			</DialogBody>
			<DialogFooter>
				<>
					<Button onPress={modal.hide} data-pendo-id="newtoken-cancel_btn">
						{t('new_token_modal_button_cancel_label', 'Cancel')}
					</Button>
					<Button
						variant="primary"
						data-test-id="new-token-modal-continue"
						data-pendo-id="newtoken-continue_btn"
						onPress={() => {
							modal.resolve({
								url: tokenTypeRouteMap[selectedOption],
								type: selectedOption,
							})
							modal.remove()
						}}
					>
						{t('new_token_modal_button_create_new_label', 'Create token')}
					</Button>
				</>
			</DialogFooter>
		</Dialog>
	)
}
export default NiceModal.create(NewTokenModal)
