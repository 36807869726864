import React from 'react'

import { keyframes, styled } from '@wpmedia/ads-system'
import { Text } from '@wpmedia/ads-typography'

export let Strong = (props) => <Text as="strong" {...props}></Text>

export let Table = styled('table', {
	width: '100%',
	borderCollapse: 'collapse',
})

export let TableRowWrapper = styled('tr', {
	borderBottom: '1px solid $gray300',
	background: '$white',
	'&:last-child': {
		borderBottom: 'none',
	},
})

export let TableData = styled('td', {
	border: 'none',
	'& div': {
		fontSize: '14px',
		lineHeigth: '$2',
	},
	'&:first-child': {
		paddingLeft: '$4',
	},
})

export let TableRestrictedHeaderData = styled('td', {
	padding: '$1',
	border: 'none',
	backgroundColor: '$gray700',
	'& div': {
		fontSize: '14px',
		lineHeigth: '$2',
		color: '$white',
		fontWeight: 'bold',
	},
	'&:first-child': {
		paddingLeft: '$2',
	},
})

export let TableRestrictedContentData = styled('td', {
	padding: '$1',
	border: 'none',
	backgroundColor: '$gray200',
	'& div': {
		fontSize: '14px',
		lineHeigth: '$2',
		fontWeight: 'bold',
	},
	'&:first-child': {
		paddingLeft: '$2',
	},
})

export let TableCell = ({ children, css = {}, ...props }) => (
	<TableData {...props} css={{ padding: 0, ...css }}>
		<div>{children}</div>
	</TableData>
)

export let TableRestrictedHeader = ({ children, ...props }) => (
	<TableRestrictedHeaderData {...props}>
		<div>{children}</div>
	</TableRestrictedHeaderData>
)

export let TableRestrictedContent = ({ children, ...props }) => (
	<TableRestrictedContentData colSpan="6" {...props}>
		<div>{children}</div>
	</TableRestrictedContentData>
)

let ripple = keyframes({
	'0%': {
		boxShadow: '0px 0px 0px 1px rgba(0, 0, 0, 0)',
	},
	'50%': {
		boxShadow: '0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1)',
	},
	'100%': {
		boxShadow: '0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0)',
	},
})

let commonRadio = {
	position: 'absolute',
	content: '""',
	borderRadius: '50%',
	transition: 'all 0.3s ease',
	transitionProperty: 'transform, border-color',
}

export let RadioButton = styled('div', {
	textAlign: 'left',
	background: '$white',
	borderColor: '$gray300',
	cursor: 'pointer',
	padding: '0 $6',
	display: 'flex',
	alignItems: 'center',
	height: '$6',
	position: 'relative',
	margin: '$0',

	'&:hover': {
		backgroundColor: '$gray300',
	},
	'&:before': {
		...commonRadio,
		left: '$1',
		top: '$2',
		width: '$2',
		height: '$2',
		border: '1px solid rgba(0, 0, 0, 0.54)',
	},
	'&:after': {
		...commonRadio,
		top: 'calc($3 - 3px)',
		left: 'calc($2 - 3px)',
		width: '8px',
		height: '8px',
		transform: 'scale(0)',
		background: '$white',
	},
})

export let TextDescription = styled(Text, {
	fontWeight: 500,
	lineHeight: '$h2',
})

export let Title = styled(Text, {
	lineHeight: '$h2',
	color: 'inherit',
})

export let OptionWrapper = styled('div', {
	borderWidth: '0',
	background: '$white',
	p: '$0',
	display: 'flex',
})

export let InputRadio = styled('input', {
	display: 'none',

	'&:checked + div:before': {
		borderColor: '$purple700',
		backgroundColor: '$purple700',
		animation: `${ripple} 0.2s linear forwards`,
	},

	'&:checked + div:after': {
		transform: 'scale(1)',
	},
	'&:checked + div': {
		color: '$purple700',
		backgroundColor: '$purple100',
	},

	'&:checked + label:before': {
		borderColor: '$purple700',
		backgroundColor: '$purple700',
		animation: `${ripple} 0.2s linear forwards`,
	},

	'&:checked + label:after': {
		transform: 'scale(1)',
	},
	'&:checked + label': {
		color: '$purple700',
		backgroundColor: '$purple100',
	},
})
