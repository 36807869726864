const WEEK_IN_MILLISECONDS = 604800000
const DAY_IN_MILLISECONDS = 86400000
const HOUR_IN_MILLISECONDS = 3600000
const MINUTE_IN_MILLISECONDS = 60000
const SECOND_IN_MILLISECONDS = 1000

/**
 * Converts an ISO date string to a relative date string
 * Credit: https://stackoverflow.com/questions/67373795/how-to-use-intl-formatrelativetime-in-the-correct-way
 * @param date ISO date string
 * @returns localized relative date string
 */
export function getRelativeDate(date: string, language = 'en') {
	let formatter = new Intl.RelativeTimeFormat(language, { style: 'long' })

	let now = Date.now()
	let then = new Date(date).getTime()
	let diff = then - now

	// Find the closest unit of time to the difference,
	// e.g., we want it to say "1 day ago" and not "30 hours ago"
	if (Math.abs(diff) > WEEK_IN_MILLISECONDS)
		return formatter.format(Math.trunc(diff / WEEK_IN_MILLISECONDS), 'week')
	else if (Math.abs(diff) > DAY_IN_MILLISECONDS)
		return formatter.format(Math.trunc(diff / DAY_IN_MILLISECONDS), 'day')
	else if (Math.abs(diff) > HOUR_IN_MILLISECONDS)
		return formatter.format(
			Math.trunc((diff % DAY_IN_MILLISECONDS) / HOUR_IN_MILLISECONDS),
			'hour',
		)
	else if (Math.abs(diff) > MINUTE_IN_MILLISECONDS)
		return formatter.format(
			Math.trunc((diff % HOUR_IN_MILLISECONDS) / MINUTE_IN_MILLISECONDS),
			'minute',
		)

	return formatter.format(
		Math.trunc((diff % MINUTE_IN_MILLISECONDS) / SECOND_IN_MILLISECONDS),
		'second',
	)
}

/**
 * Converts an ISO date string to the "YYYY-MM-DD HH:MM" format with the browser's timezone
 * @param date ISO date string
 * @returns formatted date string with timezone
 */
export function getFormattedDateWithTimezone(date: string) {
	let formattedDate = new Date(date)

	let formattedString = formattedDate.toLocaleString('en-US', {
		year: 'numeric',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
	})

	return formattedString.replace(',', '') // Remove the comma from the formatted string
}
