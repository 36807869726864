import { useTranslation } from 'react-i18next'
import { getFormattedDateWithTimezone } from './relative-date'

export const RelativeDate = ({ children = '' }: { children?: string }) => {
	let { t } = useTranslation()
	if (children !== '') {
		return <>{getFormattedDateWithTimezone(children)}</>
	} else {
		return <>{t('view_token_empty_last_used_placeholder_label', 'Never')}</>
	}
}
