import { VStack, Box } from '@wpmedia/ads-layout'
import { Text } from '@wpmedia/ads-typography'
import { useFormContext } from 'react-hook-form'
import { Trans } from 'react-i18next'
import { SmallProvider } from '@wpmedia/ads-system'
import { SubmitNewToken } from '../../../types'

export function TokenDescriptionTextarea() {
	let { register } = useFormContext<SubmitNewToken>()

	return (
		<VStack>
			<Text as="label" css={{ fontWeight: '500' }} htmlFor="token-description">
				<Trans i18nKey="new_token_description_field_label">
					Token description{' '}
					<SmallProvider>
						<Text as="span">(required)</Text>
					</SmallProvider>
				</Trans>
			</Text>
			<textarea
				data-pendo-id="token_description_input"
				cols={60}
				rows={10}
				id="token-description"
				{...register('name', { required: true })}
			></textarea>
			<SmallProvider>
				<Box css={{ color: '$gray500' }}>
					<Text>
						<Trans i18nKey="new_token_description_field_info_label">
							Give this token a helpful description, so others can understand
							the context of this token.
						</Trans>
					</Text>
				</Box>
			</SmallProvider>
		</VStack>
	)
}
