import ReactDOM from 'react-dom'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import pkg from '../package.json'
import App from './App.tsx'

import './shared/i18n'
import { Notifications } from '@wpmedia/ads-notification'

let queryClient = new QueryClient()

// if (process.env.NODE_ENV === 'production') {
// 	import('@datadog/browser-rum-slim')
// 		.then(({ datadogRum }) => {
// 			datadogRum.init({
// 				applicationId: '368c8d3d-92fa-4df5-8092-08acbe0805c4',
// 				clientToken: process.env.DATADOG_CLIENT_TOKEN,
// 				site: 'datadoghq.com',
// 				service: 'arc-dev-center',
// 				env: process.env.ARC_ENV,
// 				version: pkg.version,
// 				sampleRate: 100,
// 				premiumSampleRate: 5,
// 				trackInteractions: true,
// 				allowedTracingOrigins: [/https:\/\/.*\.arcpublishing\.com/],
// 				tracingSampleRate: 100,
// 			})
// 		})
// 		.catch(console.error)
// }

ReactDOM.render(
	<QueryClientProvider client={queryClient}>
		<Notifications />
		<App />
		{process.env.NODE_ENV === 'development' && (
			<ReactQueryDevtools initialIsOpen={false} />
		)}
	</QueryClientProvider>,
	document.getElementById('root'),
)
