import { IconButton } from '@wpmedia/ads-button'
import { Close } from '@wpmedia/ads-icon/Close'
import { Search } from '@wpmedia/ads-icon/Search'
import { Box, HStack } from '@wpmedia/ads-layout'
import { SmallProvider, styled } from '@wpmedia/ads-system'
import { useEffect, useState } from 'react'
import { useDebounce } from '@shared/hooks'
import { useTranslation } from 'react-i18next'

// @ts-expect-error ADS type issue
let Input = styled('input', {
	flex: 1,
	height: '$4',
	lineHeight: '$2',
	fontSize: '14px',
	outline: 'none',
	p: '$0',
	paddingLeft: '$4',
	width: '100%',
})

// @ts-expect-error ADS type issue
let IconWrapper = styled(Box, {
	position: 'absolute',
	width: '$4',
	height: '$4',
	justifyContent: 'center',
	alignItems: 'center',
	display: 'flex',
})

interface Props {
	placeholder: string
	onChange: (searchTerm: string) => void
	onClear: () => void
}

export function NewTokenSearchBox({ placeholder, onChange, onClear }: Props) {
	let [searchTerm, setSearchTerm] = useState('')
	let debouncedSearchTerm: string = useDebounce(searchTerm, 300)
	let { t } = useTranslation()

	useEffect(() => {
		if (debouncedSearchTerm != null) {
			onChange(debouncedSearchTerm)
		}
	}, [debouncedSearchTerm, onChange])

	let _onClear = () => {
		setSearchTerm('')
		onClear()
	}

	return (
		<Box css={{ flex: 1, maxWidth: '40%' }}>
			<HStack css={{ position: 'relative' }}>
				<IconWrapper>
					<Search />
				</IconWrapper>
				<Input
					type="text"
					value={searchTerm}
					onChange={(e) => setSearchTerm(e.target.value)}
					placeholder={placeholder}
					data-pendo-id="tokens_search_input"
				/>
				{searchTerm !== '' && (
					<SmallProvider>
						<IconWrapper css={{ right: '$0' }}>
							<IconButton
								variant="ghost"
								label={t('search_button_label_clear', 'Clear')}
								icon={<Close />}
								onPress={_onClear}
							/>
						</IconWrapper>
					</SmallProvider>
				)}
			</HStack>
		</Box>
	)
}
