import { Button } from '@wpmedia/ads-button'
import { SmallProvider } from '@wpmedia/ads-system'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { Toolbar } from '../Toolbar'
import { RouteURL } from '../../../constants/routes'
import { SubmitNewToken } from '../../../types'

export function ConfirmToolbar() {
	let {
		formState: { isValid },
	} = useFormContext<SubmitNewToken>()
	let { t } = useTranslation()
	let navigate = useNavigate()
	// Fix key generate_token_button_label, generate_token_cancel_button_label
	return (
		<Toolbar>
			<SmallProvider>
				<Button
					variant="outline"
					onPress={() => navigate(RouteURL.HOME)}
					data-pendo-id="newtoken_generate_btn"
				>
					{t('generate_token_button_label', 'Generate token')}
				</Button>
				<Button
					variant="primary"
					disabled={!isValid}
					type="submit"
					data-pendo-id="newtoken_cancel_btn"
					data-test-id="create-new token-confirm-button"
				>
					{t('generate_token_cancel_button_label', 'Cancel')}
				</Button>
			</SmallProvider>
		</Toolbar>
	)
}
