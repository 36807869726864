import NiceModal from '@ebay/nice-modal-react'
import { MenuOption, OverflowMenu } from '@wpmedia/ads-menu'
import RevokeTokenModal from '../RevokeTokenModal'
import { ViewTokenDrawer } from '../ViewToken'

import { useCloneToken } from '@shared/hooks/use-clone-token'
import { useTranslation } from 'react-i18next'
import { Token } from '../../types'
import { useRevokedEnabled } from './useRevokeEnabled'

interface Props {
	token: Token
}

export function ActionMenu({ token }: Props) {
	let { t } = useTranslation()
	let clone = useCloneToken(token.id)
	let revokeEnabled = useRevokedEnabled(token)
	if (token === undefined) return null

	return (
		<OverflowMenu
			label={t('token_actions_button_label', 'Token actions')}
			onAction={(key) => {
				if (key === 'view') void NiceModal.show(ViewTokenDrawer, { token })
				else if (key === 'revoke')
					void NiceModal.show(RevokeTokenModal, {
						name: token.name,
						username: token.username,
						id: token.id,
					})
				else if (key === 'clone') {
					clone()
				}
			}}
		>
			<MenuOption value="view" data-pendo-id="tokens_list_view_option">
				{t('label_view_menu_item', 'View Token')}
			</MenuOption>
			<MenuOption value="clone" data-pendo-id="tokens_list_clone_option">
				{t('label_clone_menu_item', 'Clone Token')}
			</MenuOption>
			<MenuOption
				color="destructive"
				value="revoke"
				isDisabled={!revokeEnabled}
				data-pendo-id="tokens_list_revoke_option"
			>
				{t('label_revoke_menu_item', 'Revoke Token')}
			</MenuOption>
		</OverflowMenu>
	)
}
