import { keyframes, styled } from "@wpmedia/ads-system";
import { Text } from "@wpmedia/ads-typography";
import React from "react";

export let Strong = (props) => <Text as="strong" {...props}></Text>;

let ripple = keyframes({
  "0%": {
    boxShadow: "0px 0px 0px 1px rgba(0, 0, 0, 0)",
  },
  "50%": {
    boxShadow: "0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0.1)",
  },
  "100%": {
    boxShadow: "0px 0px 0px $md-radio-ripple-size rgba(0, 0, 0, 0)",
  },
});

let commonRadio = {
  position: "absolute",
  content: '""',
  borderRadius: "50%",
  transition: "all 0.3s ease",
  transitionProperty: "transform, border-color",
};

export let RadioButton = styled("div", {
  flex: 1,
  textAlign: "left",
  background: "$white",
  borderColor: "$gray300",
  cursor: "pointer",
  padding: "16px 48px",
  display: "inline-block",
  position: "relative",

  "&:hover": {
    backgroundColor: "$gray200",
  },
  "&:before": {
    ...commonRadio,
    left: "$1",
    top: "$2",
    width: "$2",
    height: "$2",
    border: "1px solid rgba(0, 0, 0, 0.54)",
  },
  "&:after": {
    ...commonRadio,
    top: "calc($3 - 3px)",
    left: "calc($2 - 3px)",
    width: "8px",
    height: "8px",
    transform: "scale(0)",
    background: "$white",
  },
  variants: {
    disabled: {
      true: {
        pointerEvents: "none",
        "&:hover": {
          backgroundColor: "$white",
        },
      },
    },
  },
});

export let TextDescription = styled(Text, {
  fontWeight: 500,
  lineHeight: "$h2",
});

export let Title = styled(Strong, {
  fontWeight: 700,
  lineHeight: "$h2",
  color: "inherit",
});

export let OptionWrapper = styled("button", {
  borderStyle: "solid",
  borderColor: "$gray300",
  borderWidth: "0 1px 1px 1px",
  background: "$white",
  p: "$0",
  display: "flex",
  "&:first-child": {
    borderTopWidth: "1px",
  },
});

export let InputRadio = styled("input", {
  display: "none",

  "&:checked + div:before": {
    borderColor: "$purple700",
    backgroundColor: "$purple700",
    animation: `${ripple} 0.2s linear forwards`,
  },

  "&:checked + div:after": {
    transform: "scale(1)",
  },
  "&:checked + div": {
    color: "$purple700",
    backgroundColor: "$purple100",
  },
});
