import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector, {
	DetectorOptions,
} from 'i18next-browser-languagedetector'
import ICU from 'i18next-icu'

import TRANSLATIONS_EN from '../translations/en.json'
import TRANSLATIONS_ES from '../translations/es.json'
import TRANSLATIONS_DE from '../translations/de.json'
import TRANSLATIONS_FR from '../translations/fr.json'
import TRANSLATIONS_KO from '../translations/ko.json'
import TRANSLATIONS_JA from '../translations/ja.json'
import TRANSLATIONS_PT from '../translations/pt.json'

let detection: DetectorOptions = {
	order: ['navigator', 'htmlTag'],
	htmlTag: document.documentElement,
}

i18n
	.use(ICU)
	.use(LanguageDetector)
	.use(initReactI18next)
	.init({
		detection,
		supportedLngs: ['en', 'es', 'de', 'fr', 'ko', 'ja', 'pt'],
		saveMissing: true,
		saveMissingTo: 'all',
		keySeparator: false,
		interpolation: {
			escapeValue: false,
		},
		ns: ['translation'],
		defaultNS: 'translation',
		load: 'languageOnly',
		cleanCode: true,
		fallbackLng: 'en',
		resources: {
			en: {
				translation: TRANSLATIONS_EN,
			},
			es: {
				translation: TRANSLATIONS_ES,
			},
			de: {
				translation: TRANSLATIONS_DE,
			},
			fr: {
				translation: TRANSLATIONS_FR,
			},
			ko: {
				translation: TRANSLATIONS_KO,
			},
			ja: {
				translation: TRANSLATIONS_JA,
			},
			pt: {
				translation: TRANSLATIONS_PT,
			}
		},
	})
	.catch((e) => console.error(e))

export default i18n
