import { styled } from '@wpmedia/ads-system'

// @ts-expect-error There is an issue with the type definition of styled
export let Table = styled('table', {
	position: 'relative',
	borderCollapse: 'collapse',
	width: '100%',
	backgroundColor: '$tableDefaultRestBg',
	// tableLayout: 'fixed',
})

// @ts-expect-error There is an issue with the type definition of styled
export let Head = styled('thead', {
	padding: '0 $2',
	'& th:first-child, th:last-child': {
		paddingLeft: '$4',
	},
})

// @ts-expect-error There is an issue with the type definition of styled
export let HeadRow = styled('tr', {
	borderBottom: '$1 solid $bgLighter',
	backgroundColor: '$bgLightest',
})

// @ts-expect-error There is an issue with the type definition of styled
export let HeadCell = styled('th', {
	padding: '$1 $2',
	color: '$tableDefaultText',
	position: 'sticky',
	top: 0,
	backgroundColor: '$bgLightest',
	textAlign: 'left',
	variants: {
		isSorted: {
			asc: {
				backgroundColor: '$bgLighter',
			},
			desc: { backgroundColor: '$bgLighter' },
		},
		isSortable: {
			true: {
				cursor: 'pointer',
				'&:hover': {
					backgroundColor: '$bgLighter',
				},
			},
		},
	},
})

// @ts-expect-error There is an issue with the type definition of styled
export let Row = styled('tr', {
	borderBottom: '1px solid $tableBorder',
	'&:first-of-type': {
		borderTop: '1px solid $tableBorder',
	},
	'& td:first-child, td:last-child': {
		paddingLeft: '$4',
	},
	'& td': {
		padding: '4px $2',
	},
})

// @ts-expect-error There is an issue with the type definition of styled
export let Cell = styled('td', { wordWrap: 'break-word' })
