import { TokenType, TokenTypes } from '../types/token.types'
/**
 * Route constants for react-router. Ensure that if we change the URLs
 * no other code has to change besides the route tree.
 */
export const RouteURL = {
	HOME: '/dev-center',
	NEW_TOKEN_CREATED: 'new/created',
	NEW_RESTRICTED: 'new/restricted',
	NEW_ADMIN: 'new/general',
	NEW_READ_ONLY: 'new/readonly',
}

export let tokenTypeRouteMap: { [x in TokenTypes]: string } = {
	[TokenType.ALL_ACCESS]: RouteURL.NEW_ADMIN,
	[TokenType.READ_ONLY]: RouteURL.NEW_READ_ONLY,
	[TokenType.RESTRICTED]: RouteURL.NEW_RESTRICTED,
}

export let tokenTypeParamMap: { [key in string]: TokenTypes } = {
	general: TokenType.ALL_ACCESS,
	readonly: TokenType.READ_ONLY,
	restricted: TokenType.RESTRICTED,
}
