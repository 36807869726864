export const PERMISSIONS = {
	VIEW_ALL: 'VA',
	REVOKE: 'EA',
	TILE_APP: 'AAPP',
	CREATE_READONLY: 'R',
	CREATE_ADMIN: 'E',
} as const

let AccessPermissionsCollection = [...Object.values(PERMISSIONS)] as const

export type AccessPermissions = typeof AccessPermissionsCollection[number]
