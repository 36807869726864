import type { UserDataResponse, Token } from './types'
import {
	Configuration,
	ApplicationsApi,
	TokensApi,
	MaxNumberTokenReached,
	PostTokenRequest,
	PermissionScope,
	CreateTokenResult,
} from '@wpmedia/arc-access-v2-api'

export interface CreateTokenRequest
	extends Omit<PostTokenRequest, 'permissionScope'> {
	permissionScope?: 'readOnly' | 'general' | PermissionScope
}

export interface CreateTokenResponse extends CreateTokenResult {
	restricted?: PermissionScope
}

let config = new Configuration({
	basePath: '/access-v2',
})
export let applicationsApi = new ApplicationsApi(config)

export let tokensApi = new TokensApi(config)

export let getUserInfo = async () => {
	let response = await fetch('/user.json', {
		method: 'GET',
		credentials: 'include',
	})

	return (await response.json()) as UserDataResponse
}

export let getTokens = async (query: string, showAll = true) => {
	let params = new URLSearchParams()
	if (query !== undefined) {
		params.set('q', query)
	}
	if (showAll) {
		params.set('showAll', '1')
	}

	let response = await fetch(`/access/api/v2/tokens?${params.toString()}`, {
		method: 'GET',
		credentials: 'include',
	})

	if (response.status >= 400) {
		let text = await response.text()
		if (text !== undefined) {
			throw new Error(text)
		} else {
			throw new Error(`Error ${response.status} - ${response.statusText}`)
		}
	} else {
		return (await response.json()) as Token[]
	}
}

export class MaxNumberTokenReachedError extends Error {
	limit: number
	errCode: string
	constructor(message: string, limit: number, errCode: string) {
		super(message)
		this.limit = limit
		this.errCode = errCode
	}
}

export const createToken = async ({
	name,
	permissionScope,
	tokenType,
}: {
	name: string
	permissionScope: string
	tokenType: string
}) => {
	let token = { name, permissionScope, tokenType }
	if (tokenType === 'readonly') {
		token.permissionScope = 'readOnly'
		token.tokenType = 'readOnly'
	}
	let body = JSON.stringify(token)
	let response = await fetch('/access/api/v2/tokens', {
		method: 'POST',
		body,
		headers: { 'content-type': 'application/json' },
		credentials: 'include',
	})

	let responseJson: unknown = await response.json()
	if (!response.ok) {
		if (isMaxNumberTokenReached(responseJson)) {
			throw new MaxNumberTokenReachedError(
				responseJson.message,
				responseJson.limit,
				responseJson.errCode,
			)
		}
		throw new Error(
			`Unable to create token: ${response.status} - ${response.statusText}`,
		)
	}
	// eslint-disable-next-line @typescript-eslint/no-unsafe-return
	return responseJson as CreateTokenResponse
}

export function isMaxNumberTokenReached(
	obj: unknown,
): obj is MaxNumberTokenReached {
	return (
		typeof obj === 'object' &&
		obj !== null &&
		'errCode' in obj &&
		'limit' in obj &&
		'message' in obj
	)
}
