import { PermissionsGate, useApplications } from '@shared/application-data'
import { AccessLevel } from '@wpmedia/arc-access-v2-api'
import { FieldPath, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PERMISSIONS, SubmitNewToken } from '../../types'
import { Option } from './Option'
import {
	Table,
	TableCell,
	TableRestrictedContent,
	TableRestrictedHeader,
	TableRowWrapper,
	Title,
} from './styles'

type FieldName = FieldPath<SubmitNewToken>

export function RestrictedTokenTable() {
	let { getValues, setValue } = useFormContext<SubmitNewToken>()
	let { t } = useTranslation()

	let applications = useApplications()

	let selectAll = (access: AccessLevel) => {
		Object.keys(getValues('restricted')).map((app) =>
			// Using the generic setValue('restricted', { object with all keys }) did not set the nested
			// object properly in the hook-form state, so calling for each item instead
			setValue(`restricted.${app}` as FieldName, access),
		)
	}

	return (
		<>
			<Table css={{ marginBottom: '50px' }}>
				<thead>
					<TableRowWrapper>
						<TableRestrictedHeader>
							{t(
								'tabla_create_restricted_token_column_application',
								'Application:',
							)}
						</TableRestrictedHeader>
						<TableRestrictedHeader>
							{t('tabla_create_restricted_token_column_endpoint', 'Endpoints:')}
						</TableRestrictedHeader>
						<TableRestrictedHeader>
							{t(
								'tabla_create_restricted_token_column_description',
								'Description:',
							)}
						</TableRestrictedHeader>
						<TableRestrictedHeader onClick={() => selectAll(AccessLevel.NONE)}>
							{t(
								'tabla_create_restricted_token_column_select_all',
								'Select All',
							)}
						</TableRestrictedHeader>
						<TableRestrictedHeader
							onClick={() => selectAll(AccessLevel.READONLY)}
						>
							{t(
								'tabla_create_restricted_token_column_select_all',
								'Select All',
							)}
						</TableRestrictedHeader>
						<PermissionsGate privileges={[PERMISSIONS.CREATE_ADMIN]}>
							{(permissionGranted) =>
								permissionGranted ? (
									<TableRestrictedHeader
										onClick={() => selectAll(AccessLevel.ALL_ACCESS)}
									>
										{t(
											'tabla_create_restricted_token_column_select_all',
											'Select All',
										)}
									</TableRestrictedHeader>
								) : null
							}
						</PermissionsGate>
					</TableRowWrapper>
				</thead>
				<tbody>
					{applications.groups.map(
						({ name: categoryName, list: categories }, groupId) => {
							let arrayRows = []
							arrayRows.push(
								<TableRowWrapper key={groupId}>
									<TableRestrictedContent>
										{categoryName}
									</TableRestrictedContent>
								</TableRowWrapper>,
							)
							arrayRows.push(
								categories.map(
									({ id, application, ...endpoint }, categoryIndex) => {
										let name = `restricted.${id}` as FieldName

										return (
											<TableRowWrapper key={`row_${groupId}_${categoryIndex}`}>
												<TableCell css={{ paddingRight: '$2' }}>
													<Title css={{ whiteSpace: 'pre' }}>
														{application}
													</Title>
												</TableCell>
												<TableCell css={{ paddingRight: '$2' }}>
													<Title css={{ whiteSpace: 'pre' }}>
														{endpoint.url}
													</Title>
												</TableCell>
												<TableCell>{endpoint.description}</TableCell>
												<TableCell>
													<Option
														name={name}
														label={t('none_endpoint_name', 'None')}
														value={AccessLevel.NONE}
														id={`none_${groupId}_${categoryIndex}`}
													/>
												</TableCell>
												<TableCell>
													<Option
														name={name}
														label={t('readonly_endpoint_name', 'Read Only')}
														value={AccessLevel.READONLY}
														id={`readonly_${groupId}_${categoryIndex}`}
													/>
												</TableCell>
												<PermissionsGate
													privileges={[PERMISSIONS.CREATE_ADMIN]}
												>
													{(permissiongranted) =>
														permissiongranted ? (
															<TableCell>
																<Option
																	name={name}
																	label={t('full_endpoint_name', 'Full Access')}
																	value={AccessLevel.ALL_ACCESS}
																	id={`full_${groupId}_${categoryIndex}`}
																/>
															</TableCell>
														) : null
													}
												</PermissionsGate>
											</TableRowWrapper>
										)
									},
								),
							)
							return arrayRows
						},
					)}
				</tbody>
			</Table>
		</>
	)
}
