import { Snackbar } from '@wpmedia/ads-alert'
import { Button } from '@wpmedia/ads-button'
import { Box, Flex, Page, VStack } from '@wpmedia/ads-layout'
import { Option, Select as SelectOption } from '@wpmedia/ads-select'
import { SmallProvider } from '@wpmedia/ads-system'
import { Heading, Text } from '@wpmedia/ads-typography'
import { serviceList } from '../constants/constants'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { select, Trans } from 'react-i18next/icu.macro'
import { Navigate, useNavigate, useLocation } from 'react-router-dom'
import { CodeBlock } from '../components/core/CodeBlock'
import { Spacer } from '../components/core/Spacer'
import { Toolbar } from '../components/core/Toolbar'
import { ConfirmTokenPageState } from '../types'
import { AccessLevel } from '@wpmedia/arc-access-v2-api'

export const ConfirmNewTokenPage = () => {
	let location = useLocation()
	let navigate = useNavigate()
	let { t } = useTranslation()
	let { token, tokenType, restricted } = location.state as ConfirmTokenPageState
	let optionsCurl: typeof serviceList = []
	if (restricted !== undefined) {
		optionsCurl = serviceList.filter(
			({ id }) => restricted[id] !== AccessLevel.NONE,
		)
	}
	let [selectedApplication, setApplication] = useState(optionsCurl[0]?.id)

	if (token === undefined) return <Navigate to="/dev-center/" />

	let basePath = window.location.origin.replace(/https?:\/\//, 'https://api.')

	let defaultUrl = selectedApplication
		? optionsCurl.find(({ id }) => selectedApplication === id)?.get
		: '/site/v3/website/'
	let curl = `curl -H "Content-Type: application/json" \\
   -H "Authorization: Bearer ${token}" \\
   -X GET ${basePath}${defaultUrl}`

	let PageEnv = `CONTENT_BASE=${basePath} \nARC_ACCESS_TOKEN=${token}`

	let goToHome = () => navigate('/dev-center', { replace: true })

	return (
		<Page bg={'$gray100'} p="$0">
			<Toolbar>
				<SmallProvider>
					<Button
						variant="outline"
						onPress={goToHome}
						data-test-id="close-new-token-page-button"
						data-pendo-id="newtoken_closenewtoken_btn"
					>
						{t('label_new_token_close_button', 'Close access token')}
					</Button>
				</SmallProvider>
			</Toolbar>

			<VStack css={{ paddingTop: '$3' }}>
				<VStack css={{ marginX: '$4' }}>
					<Heading
						level={2}
						css={{ fontSize: '26px', lineHeight: '30px', fontWeight: '500' }}
					>
						<Trans i18nKey="label_new_token_created_title">
							{select`${tokenType}, general { Generated all access token } readonly { Generated read-only access token } other { Generated restricted access token }`}
						</Trans>
					</Heading>
					<Flex
						css={{
							alignItems: 'center',
							justifyContent: 'space-between',
							flexDirection: 'row',
						}}
					>
						<Flex css={{ flexDirection: 'column', flex: 1 }}>
							<VStack>
								<CodeBlock css={{ marginTop: '$2' }}>{token}</CodeBlock>
								<Snackbar
									css={{ padding: '$1' }}
									status="warning"
									message={t(
										'label_new_token_only_time_warning',
										'Warning: This is the only time the token can be viewed or downloaded.',
									)}
								/>
								<Spacer></Spacer>
							</VStack>

							<VStack>
								<Heading level={3}>
									{t('label_new_token_how_to_use_title', 'General use')}
								</Heading>
								<Text>
									{t(
										'label_new_token_how_to_use_description',
										'To use this token, include it as a part of the authentication scheme when accessing the Arc API',
									)}
								</Text>

								{restricted != null && selectedApplication && (
									<Box css={{ marginTop: '$2' }}>
										<SelectOption
											onChange={setApplication}
											value={selectedApplication}
											// TODO
											label={t(
												'select_applications_button_label',
												'Applications',
											)}
											placeholder={t(
												'select_placeholder_select_application',
												'Select an application',
											)}
										>
											{optionsCurl.map((application) => (
												<Option key={application.id} value={application.id}>
													{application.application}
												</Option>
											))}
										</SelectOption>
									</Box>
								)}
								<CodeBlock css={{ marginTop: '$2' }}>{curl}</CodeBlock>
							</VStack>
							<Spacer></Spacer>
							<VStack>
								<Heading level={3}>
									{t('label_new_token_page_builder_title', 'PageBuilder use')}
								</Heading>
								<Text>
									{t(
										'label_new_token_page_builder_desc',
										'Copy the following into your PB .env file if setting up PageBuilder',
									)}
								</Text>
								<CodeBlock css={{ marginTop: '$2' }} canDownload>
									{PageEnv}
								</CodeBlock>
							</VStack>
						</Flex>
					</Flex>
				</VStack>
			</VStack>
		</Page>
	)
}
