import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useApplications } from '@shared/application-data'
import { useCloneToken } from '@shared/hooks/use-clone-token'
import { RelativeDate } from '@shared/utils/RelativeDate'
import { Button, IconButton } from '@wpmedia/ads-button'
import { Close } from '@wpmedia/ads-icon'
import { Box, Flex, HStack, VStack } from '@wpmedia/ads-layout'
import { Spinner } from '@wpmedia/ads-spinner'
import { SmallProvider, styled } from '@wpmedia/ads-system'
import { Heading } from '@wpmedia/ads-typography'
import { AccessLevel } from '@wpmedia/arc-access-v2-api'
import { useTranslation } from 'react-i18next'
import { select, Trans } from 'react-i18next/icu.macro'
import { useQuery } from 'react-query'
import { tokensApi } from '../../api2'
import { Token, TokenTypeLabels, TokenType } from '../../types'
import Drawer from '../core/Drawer'
import { Spacer } from '../core/Spacer'
import {
	Table,
	TableCell,
	TableRestrictedContent,
	TableRestrictedHeader,
	TableRowWrapper,
	Title,
} from '../RestrictedTokenTable/styles'

// @ts-expect-error ADS type issue
const Label = styled('span', {
	fontWeight: 700,
	fontSize: '14px',
	color: '#656A86',
})

// @ts-expect-error ADS type issue
const Value = styled('span', {
	fontWeight: 400,
	fontSize: '16px',
	lineHeight: '19px',
	color: '#191B34',
})

interface Props {
	token: Token
	onClose?: any
}

let ViewToken = ({ token, onClose }: Props) => {
	let { t } = useTranslation()
	let modal = useModal()
	let tokenQuery = useQuery(['token', token.id], () =>
		tokensApi.getToken({ id: token.id }),
	)
	let { groups } = useApplications()
	let isRestricted = token?.tokenType === TokenType.RESTRICTED
	let clone = useCloneToken(token.id)

	return (
		<Drawer isOpen={modal.visible} onClose={modal.remove}>
			<Flex
				css={{
					p: '$2',
					flexDirection: 'column',
					height: '100%',
					background: '$gray100',
				}}
			>
				<Box
					css={{
						alignContent: 'space-between',
						display: 'flex',
					}}
				>
					<Box css={{ flex: 1 }}>
						<Heading level={2}>
							{t('view_access_token_title_label', 'View token access')}
						</Heading>
					</Box>
					<HStack
						css={{
							alignContent: 'space-between',
							display: 'flex',
						}}
					>
						<SmallProvider>
							<Button
								variant="outline"
								onPress={() => {
									modal.remove()
									clone()
								}}
								data-pendo-id="modal_clone_token_btn"
							>
								{t('clone_access_token_button_label', 'Clone access token')}
							</Button>
							<IconButton
								variant="ghost"
								label={t('close_view_token_modal_button_label', 'Close')}
								icon={<Close />}
								data-pendo-id="modal_close_btn"
								onPress={modal.remove}
							/>
						</SmallProvider>
					</HStack>
				</Box>

				<Spacer></Spacer>

				<Flex
					gap="$2"
					css={{ flexWrap: 'wrap', '& > div': { flexBasis: '48%' } }}
				>
					<VStack>
						<Label>
							{t('view_token_permission_decscription_label', 'Description')}
						</Label>
						<Value>{token.name}</Value>
					</VStack>
					<VStack>
						<Label>
							{t('view_token_permission_username_label', 'Username')}
						</Label>
						<Value>{token.username}</Value>
					</VStack>
					<VStack>
						<Label>{t('view_token_permission_issued_label', 'Created')}</Label>
						<Value>
							<RelativeDate>{token.issued}</RelativeDate>
						</Value>
					</VStack>
					<VStack>
						<Label>
							{t('view_token_permission_last_used_label', 'Last used')}
						</Label>
						<Value>
							<RelativeDate>{token.last_used}</RelativeDate>
						</Value>
					</VStack>
					<VStack>
						<Label>{t('view_token_permission_type_label', 'Type')}</Label>
						<Value>{TokenTypeLabels[token.tokenType]}</Value>
					</VStack>
					<VStack>
						<Label>
							{t('view_token_permission_tokenid_label', 'Token ID')}
						</Label>
						<Value>{token.id}</Value>
					</VStack>
				</Flex>
				<Spacer></Spacer>
				{isRestricted && tokenQuery.isLoading && <Spinner />}
				{isRestricted && !tokenQuery.isLoading && tokenQuery.data && (
					<VStack>
						<Box>
							<Heading level={3}>
								{t(
									'view_token_permission_resctricted_caption_table',
									'Rescricted access',
								)}
								:
							</Heading>
						</Box>
						<Table css={{ marginBottom: '50px' }}>
							<thead>
								<TableRowWrapper>
									<TableRestrictedHeader>
										{t(
											'view_token_permission_table_application_column',
											'Application:',
										)}
									</TableRestrictedHeader>
									<TableRestrictedHeader>
										{t(
											'view_token_permission_table_endpoint_column',
											'Endpoints:',
										)}
									</TableRestrictedHeader>
									<TableRestrictedHeader>
										{t(
											'view_token_permission_table_access_level_column',
											'Access level:',
										)}
									</TableRestrictedHeader>
								</TableRowWrapper>
							</thead>
							<tbody>
								{groups.map(
									({ name: categoryName, list: categories }, groupId) => {
										let arrayRows = []
										arrayRows.push(
											<TableRowWrapper key={`row_${groupId}`}>
												<TableRestrictedContent>
													{categoryName}
												</TableRestrictedContent>
											</TableRowWrapper>,
										)
										arrayRows.push(
											categories.map(
												({ id, application, ...endpoint }, categoryIndex) => {
													let accessLevel =
														tokenQuery.data?.permissionScope?.[id] ??
														AccessLevel.NONE

													return (
														<TableRowWrapper
															key={`row_${groupId}_${categoryIndex}`}
															css={{ height: '$4' }}
														>
															<TableCell css={{ paddingRight: '$2' }}>
																<Title css={{ whiteSpace: 'pre' }}>
																	{application}
																</Title>
															</TableCell>
															<TableCell css={{ paddingRight: '$2' }}>
																<Title css={{ whiteSpace: 'pre' }}>
																	{endpoint.url}
																</Title>
															</TableCell>
															<TableCell>
																<Trans i18nKey="permision_level_value_label">
																	{select`${accessLevel}, NONE { None } READONLY { Read only } ALL_ACCESS { All access } other {}`}
																</Trans>
															</TableCell>
														</TableRowWrapper>
													)
												},
											),
										)
										return arrayRows
									},
								)}
							</tbody>
						</Table>
					</VStack>
				)}
			</Flex>
		</Drawer>
	)
}

export let ViewTokenDrawer = NiceModal.create(ViewToken)
