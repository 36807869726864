import * as T from '@components/core/Table'
import { TokenLimits } from '@components/NewTokenTable/Limits'
import NiceModal from '@ebay/nice-modal-react'
import { RelativeDate } from '@shared/utils/RelativeDate'
import {
	ColumnDef,
	flexRender,
	getCoreRowModel,
	getPaginationRowModel,
	getSortedRowModel,
	RowData,
	useReactTable,
} from '@tanstack/react-table'
import { IconButton } from '@wpmedia/ads-button'
import { ArrowDown } from '@wpmedia/ads-icon/ArrowDown'
import { ArrowLeft } from '@wpmedia/ads-icon/ArrowLeft'
import { ArrowRight } from '@wpmedia/ads-icon/ArrowRight'
import { ArrowUp } from '@wpmedia/ads-icon/ArrowUp'
import { Box, Flex, HStack, VStack } from '@wpmedia/ads-layout'
import { SmallProvider } from '@wpmedia/ads-system'
import { Text } from '@wpmedia/ads-typography'
import { t } from 'i18next'
import { useTranslation } from 'react-i18next'
import { Token, TokenTypeLabels, TokenTypes, TokenType } from '../../types'
import { ViewTokenDrawer } from '../ViewToken'
import { ActionMenu } from './ActionMenu'
import { PaginationInfo } from './PaginationInfo'

const PAGE_SIZE = 10

interface Props {
	tokens: Token[]
}

export function TokenList({ tokens }: Props) {
	let { t } = useTranslation()

	let columns: Array<ColumnDef<Token>> = [
		{
			accessorKey: 'name',
			cell: (value) => <Text>{value.getValue()}</Text>,
			header: t(
				'column_header_token_permission_decscription_label',
				'Description',
			),
			meta: { css: { width: 375 } },
		},
		{
			accessorKey: 'username',
			cell: (value) => <Text>{value.getValue()}</Text>,
			header: t('column_header_token_permission_username_label', 'Username'),
			meta: { css: { width: 375 } },
		},
		{
			accessorKey: 'issued',
			cell: (value) => {
				let val = value.getValue()
				let date = val as string | undefined
				return (
					<Text>
						<RelativeDate>{date}</RelativeDate>
					</Text>
				)
			},
			header: t('column_header_token_permission_issued_label', 'Created'),
		},
		{
			accessorKey: 'last_used',
			cell: (value) => {
				let val = value.getValue()
				let date = val as string
				return (
					<Text>
						<RelativeDate>{date}</RelativeDate>
					</Text>
				)
			},
			header: t('column_header_token_permission_last_used_label', 'Last used'),
		},
		{
			accessorKey: 'tokenType',
			cell: (value) => {
				let val = value.getValue()
				let tokenTypeId = val as TokenTypes
				let tokenValues = value.row.original
				if (tokenValues.tokenType === undefined || val === undefined) {
					if (tokenValues.permissionScope.includes('ALL')) {
						tokenValues.tokenType = TokenType.ALL_ACCESS
						return <Text>{TokenTypeLabels[TokenType.ALL_ACCESS]}</Text>
					} else if (tokenValues.permissionScope.includes('GET":["*"]')) {
						tokenValues.tokenType = TokenType.READ_ONLY
						return <Text>{TokenTypeLabels[TokenType.READ_ONLY]}</Text>
					} else return <Text>{TokenTypeLabels[TokenType.RESTRICTED]}</Text>
				}
				return <Text>{TokenTypeLabels[tokenTypeId]}</Text>
			},
			header: t('column_header_token_permission_type_label', 'Token type'),
		},
		{
			id: 'actions',
			header: '',
			enableSorting: false,
			meta: { css: { width: 100 } },
			cell: (value) => <ActionMenu token={value.row.original} />,
		},
	]
	return (
		<SmallProvider>
			<Table columns={columns} data={tokens} />
		</SmallProvider>
	)
}

interface TableProps<T extends RowData> {
	columns: Array<ColumnDef<T>>
	data: T[]
}

declare module '@tanstack/table-core' {
	interface ColumnMeta {
		css?: { [key: string]: string | number }
	}
}
function Table<T extends RowData>({ columns, data }: TableProps<T>) {
	let table = useReactTable({
		data,
		columns,
		initialState: {
			pagination: {
				pageSize: PAGE_SIZE,
			},
		},
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getSortedRowModel: getSortedRowModel(),
		debugTable: true,
	})

	return (
		<>
			<Flex
				css={{
					justifyContent: 'space-between',
					alignItems: 'center',
					marginX: '$4',
				}}
			>
				<VStack>
					<PaginationInfo
						state={table.getState()}
						total={data.length}
						pages={table.getPageCount()}
					/>
					<TokenLimits />
				</VStack>
				{table.getPageCount() > 1 && (
					<HStack>
						<IconButton
							icon={<ArrowLeft />}
							label={t('token_table_prev_page_button_label', 'Previous page')}
							data-pendo-id="tokens_list_prev_page"
							onPress={() => table.previousPage()}
							isDisabled={!table.getCanPreviousPage()}
						/>
						<IconButton
							icon={<ArrowRight />}
							label={t('token_table_next_page_button_label', 'Next page')}
							data-pendo-id="tokens_list_next_page"
							onPress={() => table.nextPage()}
							isDisabled={!table.getCanNextPage()}
						/>
					</HStack>
				)}
			</Flex>
			<T.Table>
				<T.Head>
					{table.getHeaderGroups().map((headerGroup) => (
						<T.HeadRow key={headerGroup.id}>
							{headerGroup.headers.map((header) => (
								<T.HeadCell
									key={header.id}
									// @ts-expect-error More ADS/sttiches typing issues :( :( 									isSortable={header.column.getCanSort()}
									isSortable={header.column.getCanSort()}
									isSorted={header.column.getIsSorted()}
									data-pendo-id="tokens_list_sort_header"
									onClick={header.column.getToggleSortingHandler()}
									colSpan={header.colSpan}
								>
									<Flex css={{ justifyContent: 'space-between' }}>
										{!header.isPlaceholder &&
											flexRender(
												header.column.columnDef.header,
												header.getContext(),
											)}
										{{
											asc: <ArrowUp />,
											desc: <ArrowDown />,
										}[header.column.getIsSorted() as string] ?? null}
									</Flex>
								</T.HeadCell>
							))}
						</T.HeadRow>
					))}
				</T.Head>
				<tbody>
					{table.getRowModel().rows.map((row) => (
						<T.Row
							key={row.id}
							onClick={() =>
								NiceModal.show(ViewTokenDrawer, { token: row.original })
							}
							data-pendo-id="tokens_list_row"
						>
							{row.getVisibleCells().map((cell) => (
								<T.Cell key={cell.id} css={cell.column.columnDef.meta?.css}>
									{flexRender(cell.column.columnDef.cell, cell.getContext())}
								</T.Cell>
							))}
						</T.Row>
					))}
				</tbody>
			</T.Table>
			{table.getPageCount() > 1 ? (
				<Box css={{ marginX: '$4' }}>
					<PaginationInfo
						state={table.getState()}
						total={data.length}
						pages={table.getPageCount()}
					/>
				</Box>
			) : (
				<Box css={{ marginY: '$4' }} />
			)}
		</>
	)
}
