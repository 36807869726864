import { HStack } from '@wpmedia/ads-layout'

export let Toolbar = ({ children }: { children: JSX.Element }) => (
	<HStack
		css={{
			backgroundColor: '#D1DBFC',
			display: 'flex',
			flexDirection: 'column-reverse',
			px: '$4',
			py: '$2',
			justifyContent: 'flex-end',
		}}
	>
		{children}
	</HStack>
)
