import { NewTokenLayout } from '@components/layouts/NewTokenLayout'
import NiceModal from '@ebay/nice-modal-react'

import { UserPermissionsProvider } from '@shared/application-data'
import { Box, Frame } from '@wpmedia/ads-layout'
import { Spinner } from '@wpmedia/ads-spinner'
import { Fonts, globalCss } from '@wpmedia/ads-system'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import {
	BrowserRouter as Router,
	Navigate,
	Outlet,
	Route,
	Routes,
} from 'react-router-dom'
import { ConfirmNewTokenPage } from './pages/ConfirmNewToken'
import NewLandingPage from './pages/NewNewLandingPage'

const globalStyles = globalCss({
	'*': {
		boxSizing: 'border-box',
	},
	body: {
		fontSize: '14px',
		lineHeight: '$2',
	},
	'button[class*="icon-noText"]': { padding: 0 },
})

function MainLayout() {
	let { t } = useTranslation()
	let navConfig = {
		appname: t('app_name', 'Developer Center'),
		homeLink: '/dev-center',
		links: [
			{
				name: t('nav_app_api_name', 'API Access'),
				match: {
					path: '/dev-center',
				},
				sub: [],
			},
		],
		enablePendo: true,
		dropdownLinks: [],
	}
	// let client = useQueryClient()
	// eslint-disable-next-line @typescript-eslint/no-floating-promises
	// client.prefetchQuery(['user'], getUserInfo)

	return (
		<Box css={{ p: 0 }}>
			<Frame navConfig={navConfig}>
				<Suspense fallback={<Spinner />}>
					<UserPermissionsProvider>
						<NiceModal.Provider>
							<Outlet />
						</NiceModal.Provider>
					</UserPermissionsProvider>
				</Suspense>
			</Frame>
		</Box>
	)
}

export default function App() {
	globalStyles()

	return (
		<>
			<Fonts />
			<Router>
				<Routes>
					<Route path="/" element={<Navigate to="/dev-center" />} />
					<Route path="/dev-center" element={<MainLayout />}>
						<Route index element={<NewLandingPage />} />
						<Route path="new/created" element={<ConfirmNewTokenPage />} />
						<Route path="new/:tokenTypeParam" element={<NewTokenLayout />} />
					</Route>
				</Routes>
			</Router>
		</>
	)
}
