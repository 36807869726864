import { useContext } from 'react'
import { UserContext } from './ApplicationData.context'
import { ApplicationsRestricted } from '@wpmedia/arc-access-v2-api'

export let useUser = () => {
	let data = useContext(UserContext)
	if (data === undefined) {
		throw new Error('useUser must be used within a UserContext')
	}
	return data.user
}

export let useApplications = (): ApplicationsRestricted => {
	let data = useContext(UserContext)
	if (data === undefined) {
		throw new Error('useApplications must be used within a UserContext')
	}
	return data.applications
}
