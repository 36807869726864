import { styled } from '@wpmedia/ads-system'

// @ts-expect-error There is an issue with the type definition of styled
export const Spacer = styled('hr', {
	width: '100%',
	marginY: '$2',
	height: '1px',
	backgroundColor: '$gray400',
	border: '0 none',
})
