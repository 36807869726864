import { Button } from '@wpmedia/ads-button'
import {
	Dialog,
	DialogBody,
	DialogFooter,
	DialogTitle,
} from '@wpmedia/ads-dialog'
import { useNotification } from '@wpmedia/ads-notification'
import { Text } from '@wpmedia/ads-typography'
import { revokeToken } from '../../api.js'
import NiceModal, { useModal } from '@ebay/nice-modal-react'
import { useMutation, useQueryClient } from 'react-query'
import { Token } from '../TokenList/AriaTable'
import { Trans, useTranslation } from 'react-i18next'

interface Props {
	name: string
	username: string
	id: string
}

const RevokeTokenModal = ({ name, username, id }: Props) => {
	let queryClient = useQueryClient()
	let modal = useModal()
	let { snack } = useNotification()
	let { t } = useTranslation()

	/**
	 * This mutation uses the "optimistic updates" approach outlined in the docs:
	 * https://react-query.tanstack.com/docs/optimistic-updates
	 */
	let mutation = useMutation(
		(id) => {
			modal.remove()
			return revokeToken(id)
		},
		{
			onMutate: async (id: string) => {
				await queryClient.cancelQueries('tokens')
				let previousTokens = queryClient.getQueryData<Token[]>('tokens')
				queryClient.setQueryData<Token[]>(
					'tokens',
					(old) => old?.filter((token) => token.id !== id) ?? [],
				)
				queryClient.setQueryData(['getLimits'])
				return { previousTokens }
			},
			onError: (_, _tokens, context) => {
				snack.warn(t('revoke_token_snackbar_error', 'An error occurred'))
				queryClient.setQueryData('tokens', context?.previousTokens)
			},
			onSettled: () => {
				return queryClient.invalidateQueries('tokens')
			},
			onSuccess: () => {
				snack.success(t('revoke_token_snackbar', 'Access token revoked'))
			},
		},
	)
	return (
		<>
			{/* @ts-expect-error - ADS types incorrectly expect state as a required prop */}
			<Dialog
				aria-label={t('revoke_token_modal_label', 'Revoke access token')}
				size="small"
				isOpen={modal.visible}
				onDismiss={modal.hide}
			>
				<DialogTitle>
					{t('revoke_token_modal_label', 'Revoke access token')}
				</DialogTitle>
				<DialogBody>
					<Text as="label">
						<Trans i18nKey="revoke_token_modal_main_text">
							Are you sure you want to revoke {{ name }} created by{' '}
							{{ username }} ?
						</Trans>
					</Text>
				</DialogBody>
				{/* @ts-expect-error - ADS types incorrectly think children aren't allowed for a Footer */}
				<DialogFooter>
					<>
						<Button
							variant="destructive"
							onPress={() => mutation.mutate(id)}
							data-pendo-id="modal_revoke_btn"
						>
							{t(
								'revoke_token_modal_button_revoke_label',
								'Revoke access token',
							)}
						</Button>
						<Button
							onPress={() => modal.hide()}
							data-pendo-id="modal_revoke_cancel_btn"
						>
							{t('revoke_token_modal_button_cancel_label', 'Cancel')}
						</Button>
					</>
				</DialogFooter>
			</Dialog>
		</>
	)
}

export default NiceModal.create(RevokeTokenModal)
