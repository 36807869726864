import React, { useState } from 'react'
import Drawer from './Drawer'

export let useDrawer = <P,>(
	Element: React.FC<P | { onClose?: () => void }>,
): [() => JSX.Element, (props: P) => void, () => void] => {
	let [showModal, setShowModal] = useState(false)
	let [properties, setProperties] = useState({})

	let open = (props: P) => {
		setProperties(props)
		setShowModal(true)
	}
	let close = () => setShowModal(false)

	let DrawerRoot = () => {
		return (
			<Drawer isOpen={showModal} onClose={close}>
				<Element {...properties} onClose={close} />
			</Drawer>
		)
	}

	return [DrawerRoot, open, close]
}

export default Drawer
