import { PropsWithChildren, useEffect } from 'react'
import { Backdrop, DrawerContainer, DrawerUI } from './styles'
// import { createPortal } from 'react-dom'

// function createPortalRoot() {
// 	let drawerRoot = document.createElement('div')
// 	drawerRoot.setAttribute('id', 'drawer-root')
// 	return drawerRoot
// }

interface Props {
	isOpen: boolean
	onClose: () => void
	position?: 'right' | 'left'
	removeWhenClosed?: boolean
}
export type DrawerProps = PropsWithChildren<Props>

let Drawer = ({
	isOpen = false,
	children,
	onClose,
	position = 'right',
	removeWhenClosed = true,
}: DrawerProps) => {
	// let drawerRoot = document.getElementById('drawer-root')
	// let portalRootRef = useRef(drawerRoot ?? createPortalRoot())

	// let bodyRef = useRef(document.querySelector('body'))

	// useEffect(() => {
	// 	bodyRef?.current?.appendChild(portalRootRef.current)
	// 	let portal = portalRootRef.current
	// 	let bodyEl = bodyRef.current
	// 	return () => {
	// 		portal.remove()
	// 		if (bodyEl != null) bodyEl.style.overflow = ''
	// 	}
	// }, [])

	// useEffect(() => {
	// 	let updatePageScroll = () => {
	// 		if (bodyRef.current != null)
	// 			if (isOpen) {
	// 				bodyRef.current.style.overflow = 'hidden'
	// 			} else {
	// 				bodyRef.current.style.overflow = ''
	// 			}
	// 	}
	// 	updatePageScroll()
	// }, [isOpen])

	useEffect(() => {
		let onKeyPress = (e: KeyboardEvent) => {
			if (e.key === 'Escape') {
				onClose?.()
			}
		}

		if (isOpen) {
			window.addEventListener('keyup', onKeyPress)
		}

		return () => {
			window.removeEventListener('keyup', onKeyPress)
		}
	}, [isOpen, onClose])

	if (removeWhenClosed && !isOpen) {
		return null
	}

	return (
		<DrawerContainer open={true}>
			<DrawerUI position={position} role="dialog">
				{children}
			</DrawerUI>
			<Backdrop onClick={onClose} />
		</DrawerContainer>
	)
}

export default Drawer
