import type { AccessPermissions } from '../../types'
import { useCheckPermissions } from './use-check-permissions'

interface PermissionsGateProps {
	children: (permissionGranted: boolean) => JSX.Element | null
	RenderError?: () => JSX.Element
	errorProps?: { [x: string]: any }
	privileges: AccessPermissions[]
	checkAll?: boolean
}

export function PermissionsGate({
	children = (_) => <></>,
	privileges = [],
	checkAll = false,
}: PermissionsGateProps) {
	let { hasPermissions } = useCheckPermissions()
	let permissionGranted = hasPermissions(privileges, checkAll)
	return children(permissionGranted)
}
