import { useFormContext, FieldPath, useWatch } from 'react-hook-form'
import { InputRadio, OptionWrapper, RadioButton, Title } from './styles'
import { SubmitNewToken } from '../../types'

interface OptionProps {
	id: string
	value: string
	name: FieldPath<SubmitNewToken>
	label: string
	register?: any
}

export const Option = ({ id, value, label, name }: OptionProps) => {
	let { register, control } = useFormContext<SubmitNewToken>()
	let state = useWatch({ name, control })

	return (
		<OptionWrapper
			className="option_custom"
			data-test-id={`radio-button-restricted-${name}-${value}`}
		>
			<InputRadio
				{...register(name)}
				type="radio"
				checked={state === value}
				value={value}
				id={id}
			/>
			<RadioButton htmlFor={id} as="label">
				<Title css={{ whiteSpace: 'pre', fontSize: '$small' }}>{label}</Title>
			</RadioButton>
		</OptionWrapper>
	)
}
