import type { AccessPermissions } from '../../types'
import { useUser } from './use-application-data'

interface UseCheckPermissionsMethods {
	hasPermissions: (
		permissions?: AccessPermissions[] | AccessPermissions,
		checkAll?: boolean,
	) => boolean
}

export let useCheckPermissions = (): UseCheckPermissionsMethods => {
	let { permissions } = useUser()
	return {
		hasPermissions: (requiredPermissions, checkAll = false) => {
			if (requiredPermissions === undefined) {
				return true
			}

			if (!Array.isArray(requiredPermissions)) {
				requiredPermissions = [requiredPermissions]
			}

			return requiredPermissions[checkAll ? 'every' : 'some']((p) =>
				permissions.includes(p),
			)
		},
	}
}
