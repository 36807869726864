/* global fetch */
export const getUserInfo = async () => {
	const response = await fetch('/user.json', {
		method: 'GET',
		credentials: 'include',
	})

	return response.json()
}

/**
 *
 * @param {*} q
 * @param {*} showAll
 * @returns {Promise<import('./components/TokenTable/AriaTable').Token[]>}
 */
export const getTokens = async (q, showAll) => {
	const params = {}
	if (q) {
		params.q = q
	}
	if (showAll) {
		params.showAll = '1'
	}

	const qs = Object.keys(params)
		.map((k) => encodeURIComponent(k) + '=' + encodeURIComponent(params[k]))
		.join('&')

	const response = await fetch(`/access/api/v2/tokens${qs ? '?' + qs : ''}`, {
		method: 'GET',
		credentials: 'include',
	})

	if (response.status >= 400) {
		const respText = await response.text()
		if (respText) {
			throw new Error(respText)
		} else {
			throw new Error(`${response.status} - ${response.statusText}`)
		}
	} else {
		return response.json()
	}
}

export const createToken = async ({ name, permissionScope, tokenType }) => {
	let token = { name, permissionScope, tokenType }
	if (tokenType === 'readonly') {
		token.permissionScope = 'readOnly'
		token.tokenType = 'readOnly'
	}
	let body = JSON.stringify(token)
	let response = await fetch('/access/api/v2/tokens', {
		method: 'POST',
		body,
		headers: { 'content-type': 'application/json' },
		credentials: 'include',
	})
	if (!response.ok) {
		let body = await response.json()
		if (body) {
			throw new Error(body.message)
		}
		throw new Error(
			`Unable to create token: ${response.status} - ${response.statusText}`,
		)
	}

	return response.json()
}

export const revokeToken = async (id) => {
	const response = await fetch(`/access/api/v2/tokens/${id}`, {
		method: 'DELETE',
		credentials: 'include',
	}).catch((err) => console.log(err))

	if (!response.ok) {
		const body = await response.text()
		if (body) {
			throw new Error(body)
		}

		throw new Error(
			`Unable to revoke token: ${response.status} - ${response.statusText}`,
		)
	}

	return `successfully revoked token: ${id}`
}

export let getTokenLimits = async () => {
	let response = await fetch('/access-v2/tokens/limits', {
		method: 'GET',
		credentials: 'include',
	})
	return response.json()
}

export let getSettings = async () => {
	let response = await fetch('/settings-readonly/api/v1/organization', {
		method: 'GET',
		credentials: 'include',
	})
	return response.json()
}
// Errand function...never invoked
// export const getOrgTokens = async () => {
//   const response = await fetch('/access/api/v2/admin/tokens', {
//     method: 'GET',
//     credentials: 'include'
//   })

//   return response.json()
// }
