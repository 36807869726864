import endPointsList from '../components/RestrictedTokenTable/RestrictedContent.json'
import { Permission } from '../types'

export const TOKEN_PERMISSIONS = [
	{
		key: 'general',
		name: 'TOKEN_API_TYPE_ADMIN_NAME',
		description1: 'TOKEN_API_TYPE_ADMIN_DESC1',
		description2: 'TOKEN_API_TYPE_ADMIN_DESC2',
		description3: 'TOKEN_API_TYPE_ADMIN_DESC3',
		defaultPermissions: '{"ALL":["*"]}',
		classNames: {
			button: 'default',
			variant: 'default',
		},
	},
	{
		key: 'readOnly',
		name: 'TOKEN_API_TYPE_READONLY_NAME',
		description1: 'TOKEN_API_TYPE_READONLY_DESC1',
		description2: 'TOKEN_API_TYPE_READONLY_DESC2',
		description3: 'TOKEN_API_TYPE_READONLY_DESC3',
		defaultPermissions:
			'{"GET":["*"],"HEAD":["*"],"OPTIONS":["*"],"POST":["/content/v4/urls"]}',
		classNames: {
			button: '',
			variant: 'primary',
		},
	},
]

export let serviceList = endPointsList.groups.reduce<
	typeof endPointsList['groups'][0]['list']
>((prev, { list }) => [...prev, ...list], [])

export let restrictedDefaulValue = serviceList.reduce(
	(acc, { id }) => ({
		...acc,
		[id]: Permission.NONE,
	}),
	{},
)
