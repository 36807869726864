import { IconButton } from '@wpmedia/ads-button'
import { Copy, Download } from '@wpmedia/ads-icon'
import { Box, Flex } from '@wpmedia/ads-layout'
import { FunctionComponent, ReactText } from 'react'
import { useTranslation } from 'react-i18next'

function copyToClipboard(textToCopy: string) {
	// navigator clipboard api needs a secure context (https)
	if (navigator.clipboard && window.isSecureContext) {
		// navigator clipboard api method'
		return navigator.clipboard.writeText(textToCopy)
	} else {
		// text area method
		let textArea = document.createElement('textarea')
		textArea.value = textToCopy
		// make the textarea out of viewport
		textArea.style.position = 'fixed'
		textArea.style.left = '-999999px'
		textArea.style.top = '-999999px'
		document.body.appendChild(textArea)
		textArea.focus()
		textArea.select()
		return new Promise((resolve, reject) => {
			// here the magic happens
			document.execCommand('copy') ? resolve(null) : reject(new Error())
			textArea.remove()
		})
	}
}

export const CodeBlock: FunctionComponent<{
	children?: ReactText
	canCopy?: boolean
	canDownload?: boolean
	css?: any
}> = ({ children, canCopy = true, canDownload = false, css = {} }) => {
	let { t } = useTranslation()
	let handleDownload = (value: string) => {
		let element = document.createElement('a')
		// Blob is not defined in nodejs which runs standardJS Rules
		const file = new Blob([value], { type: 'application/octet-stream' }) // eslint-disable-line
		element.href = URL.createObjectURL(file)
		element.download = 'env'
		document.body.appendChild(element)
		element.click()
	}

	return (
		<Box
			as="code"
			css={{
				padding: '$1',
				pl: '$2',
				bg: '$white',
				borderColor: '$gray300',
				borderWidth: '1px',
				borderStyle: 'solid',
				borderRadius: '5px',
				flex: 1,
				...css,
			}}
		>
			<Flex
				css={{
					justifyContent: 'space-between',
					alignItems: 'center',
				}}
			>
				<Box as="pre">{children}</Box>
				<Flex css={{ flexDirection: 'row' }}>
					{canDownload && (
						<IconButton
							aria-label={t('label_button_download_script', 'Download')}
							label={t('label_button_download_script', 'Download')}
							onPress={() => handleDownload(children)}
							variant="ghost"
							data-pendo-id="newtoken_download_icon"
							icon={<Download />}
							css={{
								alignSelf: 'flex-start',
							}}
						/>
					)}
					{canCopy && (
						<IconButton
							aria-label={t('label_button_copy_script', 'Copy')}
							label={t('label_button_copy_script', 'Copy')}
							onPress={() => copyToClipboard(children)}
							data-pendo-id="newtoken_copy_icon"
							variant="ghost"
							icon={<Copy />}
							css={{
								alignSelf: 'flex-start',
							}}
						/>
					)}
				</Flex>
			</Flex>
		</Box>
	)
}
