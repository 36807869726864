import NiceModal from '@ebay/nice-modal-react'
import { Button } from '@wpmedia/ads-button'
import { Box, HStack, Page, VStack } from '@wpmedia/ads-layout'
import { SmallProvider } from '@wpmedia/ads-system'
import { Heading, Text } from '@wpmedia/ads-typography'
import { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import NewTokenModal from '@components/NewTokenModal'
import { NewTokenSearchBox } from '@components/NewTokenSearchBox'
import { TokenList } from '@components/TokenList'
import { PermissionsGate } from '@shared/application-data'
import { TokenTypes, PERMISSIONS, Token } from '../types'
import { useQuery } from 'react-query'
import { getTokens } from '../api2'

export default function TokenPage() {
	let { t } = useTranslation()
	let [value, setValue] = useState('')
	let prevTokens = useRef<Token[]>([])
	let tokenQuery = useQuery(['tokens', value], () => getTokens(value), {
		// use this feature to not update the table until we have valid data back from the new request
		// without placeholder data, we either show a loading spinner or "0 tokens" until we get a valid response
		placeholderData: prevTokens.current,
		onSuccess: (data) => {
			prevTokens.current = data
		},
	})

	let navigate = useNavigate()

	return (
		<Page bg={'$gray100'} p="$0">
			<VStack gap="$4" css={{ paddingTop: '$5' }}>
				<VStack gap="$4" css={{ marginX: '$4' }}>
					<Heading
						level={2}
						// @ts-expect-error ADS type issue
						css={{ fontSize: '26px', lineHeight: '30px', fontWeight: '500' }}
					>
						{t('label_token_name_pl', 'Access Tokens')}
					</Heading>
					<Box css={{ width: '50%' }}>
						<Text
							css={{
								color: '#656A86',
							}}
						>
							{t(
								'action_list_tokens_desc',
								'Access tokens can be used to authenticate requests to the Arc API over HTTPS using the Bearer authentication scheme. They do not expire, but they can be revoked by their owner or an administrator.',
							)}
						</Text>
					</Box>
					<HStack css={{ justifyContent: 'space-between' }}>
						<NewTokenSearchBox
							placeholder={t(
								'action_search_searchbox_placeholder',
								'Search for tokens',
							)}
							onChange={setValue}
							onClear={() => setValue('')}
						/>

						<SmallProvider>
							<PermissionsGate
								privileges={[
									PERMISSIONS.CREATE_ADMIN,
									PERMISSIONS.CREATE_READONLY,
								]}
							>
								{(permisionsGranted) => (
									<Button
										data-test-id="new-token-button"
										isDisabled={!permisionsGranted}
										variant="primary"
										data-pendo-id="tokens_new_btn"
										onPress={() =>
											NiceModal.show<{ url: string; type: TokenTypes }>(
												NewTokenModal,
											).then((option) =>
												navigate(option.url, { state: { type: option.type } }),
											)
										}
									>
										{t('new_token_button_label', 'New access token')}
									</Button>
								)}
							</PermissionsGate>
						</SmallProvider>
					</HStack>
				</VStack>
				{tokenQuery.data?.length === 0 ? (
					<Box css={{ padding: '$4' }}>
						<p className="empty-list is-size-4 has-text-dark has-text-centered">
							{t('action_list_tokens_msg_notokens', 'No active tokens found')}
						</p>
					</Box>
				) : (
					<TokenList tokens={tokenQuery.data ?? []} />
				)}
			</VStack>
		</Page>
	)
}
